main.appContainer {
  display: flex;
  justify-content: center;
  min-height: 100vh;
}

main.appContainer.sideNavExpanded {
  margin-left: var(--sidebar-width);
}

main.appContainer.sideNavCollapsed {
  margin-left: var(--sidebar-width-collapsed);
}

.app {
  padding: var(--spacing-6);
  flex-grow: 1;
  max-width: var(--app-width-max);
  overflow: auto;
  box-sizing: border-box;
}

.app.homeLayout {
  max-width: var(--app-width-max-fullscreen);
}

main.app.fullScreen {
  margin: 0;
}

main.app.centered {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  max-width: unset;
  height: 100vh;
}

main.appContainer.homeLayout {
  width: 100%;
  padding-top: var(--spacing-6);
  align-items: flex-start;
  box-sizing: border-box;
  align-items: stretch;
}
