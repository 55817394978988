.specsCell,
.specsHeader,
.notesCell,
.notesHeader,
.totalsCell {
  display: flex;
  flex-direction: column;
  width: var(--width-44);
  align-items: flex-start;
  flex-shrink: 0;
  padding: var(--spacing-1) var(--spacing-2);
  box-sizing: border-box;
  border-bottom: 1px solid var(--clr-neutral-300);
  overflow: hidden;
  position: relative;
}

.notAvailable {
  background-color: var(--clr-neutral-100);
}

.specsCell,
.notesCell {
  height: var(--width-20);
}

.specsCell.frozen,
.notesCell.frozen {
  background-color: var(--clr-neutral-200);
}

.notesHeader,
.specsHeader {
  height: var(--width-8);
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  color: var(--clr-neutral-500);
}

.notesHeader,
.notesCell {
  width: var(--width-72);
  flex-grow: 1;
}

.titles {
  width: var(--width-56);
  border-right: 1px solid var(--clr-neutral-300);
  gap: var(--spacing-2);
}

.specifications {
  width: var(--width-44);
}

.totalsCell,
.price {
  width: var(--width-32);
}

.totalsCell {
  border: unset;
}

.price {
  border-left: 1px solid var(--clr-neutral-300);
}
