.slideshowContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--clr-neutral-200);
  padding-top: 56px;
  z-index: 1000;
}

.slideshowContainer.asideExpanded {
  padding-right: var(--slideshow-aside-width);
}
