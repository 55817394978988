.button {
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-size: var(--text-base-size);
  line-height: var(--text-base-height);
  padding: var(--spacing-2) calc(var(--spacing-6) - 1px);
  color: var(--clr-neutral-500);
  gap: var(--spacing-2);
  height: calc(var(--text-base-height) + 2 * var(--spacing-2));
  cursor: pointer;
  border-radius: var(--rounded);
  flex-grow: 0;
}

.button.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.base {
  background-color: white;
  color: var(--clr-neutral-500);
  border: 1px solid var(--clr-neutral-500);
}
.base:hover {
  background-color: var(--clr-neutral-100);
  color: var(--clr-neutral-700);
  border-color: var(--clr-neutral-700);
}

.inline {
  background-color: transparent;
  color: var(--clr-neutral-500);
  height: var(--text-base-height);
  padding: 0 var(--spacing-1);
  gap: var(--spacing-1);
}
.inline:hover {
  color: var(--clr-neutral-700);
  text-decoration: underline;
}

.rounded {
  background-color: white;
  border-radius: var(--rounded-full);
  padding: var(--spacing-2) var(--spacing-4) var(--spacing-2) var(--spacing-3);
}
.button.iconOnly {
  padding-right: calc(var(--spacing-3) - 1px);
  padding-left: calc(var(--spacing-3) - 1px);
}
.rounded.iconOnly {
  padding-right: var(--spacing-3);
  padding-left: var(--spacing-3);
}
.rounded:hover {
  background-color: var(--clr-neutral-100);
  color: var(--clr-neutral-700);
}

.emphasized {
  color: var(--clr-sky-700);
}
.base.emphasized {
  border-color: var(--clr-sky-700);
}
.emphasized:hover {
  background-color: var(--clr-sky-100);
  color: var(--clr-sky-900);
}
.base.emphasized:hover {
  border-color: var(--clr-sky-900);
}

.warning {
  color: var(--clr-red-700);
}
.base.warning {
  border-color: var(--clr-red-700);
}
.warning:hover {
  background-color: var(--clr-red-100);
  color: var(--clr-red-900);
}
.base.warning:hover {
  border-color: var(--clr-red-900);
}

.important {
  color: white;
  background-color: var(--clr-sky-700);
  border: unset;
}
.important:hover {
  color: white;
  background-color: var(--clr-sky-900);
  border: unset;
}

.alert {
  color: white;
  background-color: var(--clr-red-700);
  border: unset;
}
.alert:hover {
  color: white;
  background-color: var(--clr-red-900);
  border: unset;
}

.inline.emphasized {
  color: var(--clr-sky-700);
}
.inline.emphasized:hover {
  color: var(--clr-sky-900);
  background-color: unset;
}
.inline.warning {
  color: var(--clr-red-700);
}
.inline.warning:hover {
  color: var(--clr-red-900);
  background-color: unset;
}
