.marker {
  position: absolute;
  background-color: var(--clr-sky-700);
  padding: 0 var(--spacing-2);
  color: white;
  cursor: pointer;
  /* border-radius: 0 var(--rounded-full) var(--rounded-full) var(--rounded-full); */
  border-radius: 0 var(--width-3) var(--width-3) var(--width-3);
  box-shadow: 0px 0px 1px 2px rgba(255, 255, 255, 0.5);
  z-index: 1000;
  opacity: 1;
  white-space: nowrap;
  transition: transform 0.3s ease;
  max-width: var(--width-56);
  white-space: wrap;
}

.marker.expandLeft {
  border-radius: var(--rounded-full) 0 var(--rounded-full) var(--rounded-full);
  border-radius: var(--width-3) 0 var(--width-3) var(--width-3);
}

.marker.nonActive {
  background-color: var(--clr-neutral-700);
  z-index: 1;
  opacity: 0.4;
}

.marker:hover {
  background-color: var(--clr-sky-900);
  opacity: 1;
  z-index: 2000;
}

.markersLoader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: wait;
  z-index: 3000;
}
