.form {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-3);
}

.form.modal {
  width: 450px;
  max-width: 100%;
}

.form.noGap {
  gap: unset;
}
