.homeLink {
  padding: var(--spacing-2) var(--spacing-3);
  font-size: var(--text-base-size);
  line-height: var(--text-base-height);
  display: flex;
  align-items: center;
  gap: var(--spacing-2);
  color: var(--clr-neutral-900);
  text-decoration: none;
}

.menuList {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.menuListItem,
.homeLink,
.projectMarker {
  padding: var(--spacing-2) var(--spacing-3);
  font-size: var(--text-base-size);
  line-height: var(--text-base-height);
  display: flex;
  align-items: center;
  gap: var(--spacing-2);
  color: var(--clr-neutral-900);
  text-decoration: none;
}

.homeLink {
  padding: var(--spacing-3) var(--spacing-4);
}

.menuListItem {
  height: var(--text-base-height);
  box-sizing: content-box;
}

.menuListItem > svg {
  padding: 0 var(--spacing-3);
}

.menuListItem:hover {
  color: var(--clr-neutral-900);
  background-color: var(--clr-neutral-300);
}

.menuListItem.active {
  background-color: var(--clr-neutral-200);
}

.returnButton {
  padding: var(--spacing-3);
  border-radius: var(--rounded-md);
}

.returnButton:hover {
  background-color: var(--clr-neutral-200);
}

.menuListContainer {
  position: relative;
}

.tags {
  position: absolute;
  top: 50%;
  right: var(--spacing-2);
  transform: translateY(-50%);
}
