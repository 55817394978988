.errorMessage {
  background-color: var(--clr-red-100);
  color: var(--clr-red-700);
  padding: var(--spacing-2);
}

.errorMessage.offscreen {
  position: absolute;
  left: -9999px;
}
