.slideshowAside {
  position: fixed;
  top: 56px;
  right: 0;
  bottom: 0;
  left: var(--slide-show-aside-left);
  width: var(--slideshow-aside-width);
  background-color: white;
  border-left: 1px solid var(--clr-neutral-300);
  padding: var(--spacing-4);
  box-sizing: border-box;
  overflow: auto;
}

.asideControl {
  position: fixed;
  right: var(--spacing-3);
  top: calc(56px + var(--spacing-3));
  background-color: white;
  display: flex;
  align-items: center;
  border-radius: var(--rounded-full);
  padding: var(--spacing-1);
  gap: var(--spacing-2);
}

.asideControl.collapsed {
  /* padding-left: var(--spacing-4); */
  box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.1);
}
