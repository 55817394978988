.zoomControls {
  position: absolute;
  right: var(--spacing-3);
  bottom: var(--spacing-3);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 24px;
  padding: var(--spacing-1);
  gap: var(--spacing-2);
  /*box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.1);*/
  padding-right: var(--spacing-4);
}

.zoomControls > * {
  box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.1);
}
