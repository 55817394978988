.link {
  display: flex;
  align-items: center;
  font-size: var(--text-base-size);
  line-height: var(--text-base-height);
  color: var(--clr-sky-700);
  height: var(--text-base-height);
  padding: 0 var(--spacing-1);
  gap: var(--spacing-1);
  white-space: nowrap;
}
