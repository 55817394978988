.slideActions {
  position: fixed;
  left: var(--spacing-3);
  top: calc(56px + var(--spacing-3));
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 24px;
  padding: var(--spacing-1);
  gap: var(--spacing-2);
  /*box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.1);*/
  padding-right: var(--spacing-4);
}

.slideActions > * {
  box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.1);
}
