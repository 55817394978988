.frameListItem,
.placeholderItem {
  width: var(--card-width);
  box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.1);
  border-radius: var(--rounded);
  overflow: hidden;
  background-color: white;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-1);
  box-sizing: border-box;
}

.withLink {
  cursor: pointer;
}

.withLink:hover {
  box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.2);
}

.frameListItem.beingReplaced {
  background-color: var(--clr-neutral-100);
}

.placeholderItem {
  background-color: var(--clr-neutral-100);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-4);
  box-sizing: border-box;
  height: var(--width-52);
  gap: var(--spacing-4);
}

.frameLoResImage {
  position: relative;
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.frameLoResImage.beingReplaced {
  opacity: 0.25;
}

.replacementMessage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1;
}

.frameMenu,
.frameVisibilityMenu,
.frameDeletedMessage {
  position: absolute;
  right: var(--spacing-2);
  z-index: 2;
}

.frameMenu {
  display: flex;
  padding: var(--spacing-1);
  border-radius: var(--rounded-full);
  top: var(--spacing-2);
  background-color: white;
  box-shadow: 0px 0 3px 1px rgba(0, 0, 0, 0.1);
}

.frameVisibilityMenu,
.frameDeletedMessage {
  display: flex;
  justify-content: flex-end;
  gap: var(--spacing-3);
  bottom: 0;
  right: 0;
  left: 0;
  padding: var(--spacing-3);
  background-color: white;
  cursor: default;
  border-top: 1px solid var(--clr-neutral-200);
}

.placeholderItem.uploading {
  background-color: var(--clr-red-100);
}

.frameDeletedMessage {
  justify-content: center;
  background-color: var(--clr-red-100);
  color: var(--clr-red-700);
}
