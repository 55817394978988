.section {
  --vertical-padding: var(--spacing-6);
  --vertical-padding-sm: var(--spacing-4);
  --vertical-padding-xs: var(--spacing-2);
  --box-gap: var(--spacing-2);
}

.section {
  /* border-top: 1px solid var(--clr-neutral-300); */
  border-bottom: 1px solid var(--clr-neutral-300);
  padding: var(--vertical-padding) var(--horizontal-padding);
  display: flex;
  flex-direction: column;
  gap: var(--gap);
}
.section:last-of-type {
  border-bottom: 1px solid var(--clr-neutral-300);
}

.section.boxed {
  border: 1px solid var(--clr-neutral-300);
  border-radius: var(--rounded-md);
  margin-bottom: var(--box-gap);
  padding: var(--vertical-padding-xs) var(--horizontal-padding);
  gap: var(--gap-sm);
}
.section.boxed.emphasized {
  border: 1px solid var(--clr-neutral-500);
}
.section.boxed:last-of-type {
  margin-bottom: unset;
}
.section.filled {
  background-color: var(--clr-neutral-100);
}

.paddingSm {
  padding-top: var(--vertical-padding-xs);
  padding-bottom: var(--vertical-padding-xs);
  gap: var(--gap-xs);
}

.section.emphasized {
  background-color: var(--clr-sky-100);
}
