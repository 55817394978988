.markableWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.canvas {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.markable {
  position: absolute;
  top: 50%;
  left: 50%;
  transform-style: flat;
  transform: translateX(-50%) translateY(-50%);
  margin: 0;
  padding: 0;
  transition: transform 0.3s ease;
}

.markable > img {
  cursor: move;
  margin: 0;
  padding: 0;
  vertical-align: middle; /* Important. If not set .markable will be slightly bigger that image */
}

.markable.markingMode > img {
  cursor: url("markerCursor.svg"), help;
}
