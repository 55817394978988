.dragAndDropArea {
  border-radius: var(--rounded);
}

.dragAndDropArea.withOutline {
  outline: 2px dashed var(--clr-neutral-300);
}

.dragAndDropArea.dragging,
.dragAndDropArea.withOutline.dragging {
  outline: 2px dashed var(--clr-sky-700);
  background-color: var(--clr-sky-100);
}

.dragAndDropArea.dragging.restricted,
.dragAndDropArea.withOutline.dragging.restricted {
  outline: 2px dashed var(--clr-red-700);
  background-color: var(--clr-red-100);
}
