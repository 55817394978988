.fileList {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: var(--spacing-1);
  flex-wrap: wrap;
  margin-top: var(--spacing-6);
}

.fileListHeader {
  margin: var(--spacing-4) 0;
}

.fileListItem {
  display: flex;
  /* box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.1); */
  border-radius: var(--rounded);
  overflow: hidden;
  background-color: white;
  padding: var(--spacing-2) var(--spacing-4);
  background-color: var(--clr-neutral-100);
  border: 1px solid var(--clr-neutral-200);
  box-sizing: border-box;
  flex-wrap: wrap;
  width: var(--filelist-width);
}
