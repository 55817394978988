.chip {
  display: flex;
  align-items: center;
  white-space: nowrap;
  gap: var(--spacing-2);
  height: calc(var(--text-base-height) + 2 * var(--spacing-2));
  width: fit-content;
  flex-grow: 0;
  border-radius: var(--rounded-full);
  font-size: var(--text-base-size);
  line-height: var(--text-base-height);
  padding: var(--spacing-2) var(--spacing-4);
  border: 1px solid var(--clr-neutral-300);
  box-sizing: border-box;
}

.withButton {
  padding-right: var(--spacing-2);
}

.roleChip,
.statusChip {
  border: unset;
  background-color: var(--clr-neutral-300);
  height: var(--width-8);
  padding: var(--spacing-1) var(--spacing-4);
}

.countChip {
  border: unset;
  background-color: var(--clr-neutral-100);
  padding: var(--spacing-1) var(--spacing-3);
}

.countChip.darker {
  background-color: rgba(0, 0, 0, 0.1);
}

.roleChip,
.countChip,
.chip.small {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  height: var(--width-6);
}

.roleChip {
  background-color: var(--clr-sky-100);
  color: var(--clr-sky-700);
}

.statusChip.open,
.statusChip.pending {
  background-color: var(--clr-green-200);
  color: var(--clr-green-900);
}

.countChip.unseen,
.statusChip.closed,
.statusChip.in_progress,
.statusChip.warranty {
  background-color: var(--clr-sky-200);
  color: var(--clr-sky-900);
}

.statusChip.inquiry {
  background-color: var(--clr-red-200);
  color: var(--clr-red-900);
}

.statusChip.offer {
  background-color: var(--clr-yellow-200);
  color: var(--clr-yellow-900);
}

.toggleChip {
  cursor: pointer;
}

.toggleChip:hover {
  background-color: var(--clr-neutral-100);
}

.toggleChip.active {
  border-color: transparent;
  color: white;
  background-color: var(--clr-sky-700);
}

.toggleChip.active:hover {
  background-color: var(--clr-sky-900);
}
