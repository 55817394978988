.slideshowControls {
  position: fixed;
  background-color: white;
  padding: var(--spacing-2) var(--spacing-4);
  box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.1);
  left: 0;
  right: 0;
  top: 0;
  display: flex;
}

.slideshowControls.obsolete {
  background-color: var(--clr-red-100);
  color: var(--clr-red-700);
}

.controlsSection {
  display: flex;
  align-items: center;
  width: calc(100vw / 2 - 100px);
  gap: var(--spacing-2);
}

.controlsSection:nth-child(1) {
  justify-content: flex-start;
}
.controlsSection:nth-child(2) {
  justify-content: center;
  width: 200px;
}
.controlsSection:nth-child(3) {
  justify-content: flex-end;
}
