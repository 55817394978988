.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing-2);
}

.pageFooter,
.sectionFooter {
  justify-content: flex-start;
}

.dialogFooter {
  flex-grow: 1;
  align-items: flex-end;
  justify-content: flex-end;
}

.pageFooter {
  margin-top: var(--spacing-6);
}

.dialogFooter {
  margin-top: var(--spacing-4);
}
