.progressContainer {
  display: flex;
  width: 100%;
  height: var(--width-2);
  background-color: var(--clr-neutral-300);
  border-radius: var(--rounded-sm);
  overflow: hidden;
}

.progressBar {
  display: flex;
  height: var(--width-2);
  background-color: var(--clr-sky-700);
}
