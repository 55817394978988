.textarea {
  height: 100px;
  padding: var(--spacing-2);
  font-size: var(--text-base-size);
  line-height: var(--text-base-height);
  border: 1px solid var(--clr-neutral-300);
  border-radius: var(--rounded);
}

.textarea:focus {
  outline-color: var(--clr-sky-700);
}
