.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--clr-neutral-500);
  background-color: var(--clr-neutral-300);
  border-radius: var(--rounded-full);
  width: var(--width-10);
  height: var(--width-10);
  overflow: hidden;
}

.small {
  width: var(--width-5);
  height: var(--width-5);
  font-size: var(--text-sm-size);
}

.avatarImage {
  height: 100%;
}
