.visualsUploaderContainer {
  width: var(--filelist-width);
}

.visualsUploader {
  padding: var(--spacing-4);
  display: flex;
  align-items: center;
  justify-content: center;
}
