.aside {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: var(--clr-neutral-100);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.aside.collapsed {
  width: var(--sidebar-width-collapsed);
}

.aside.expanded {
  width: var(--sidebar-width);
}
