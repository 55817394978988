.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pageHeader {
  gap: var(--spacing-4);
  margin-bottom: var(--spacing-6);
}

.dialogHeader,
.sectionHeader {
  margin-bottom: var(--spacing-4);
}

.alertHeader {
  color: var(--clr-red-700);
}
