:root {
  --text-sm-size: 0.75rem; /* 12px */
  --text-sm-height: 1rem; /* 16px */
  --text-base-size: 1rem; /* 16px */
  --text-base-height: 1.5rem; /* 24px */
  --text-lg-size: 1.25rem; /* 20px */
  --text-lg-height: 1.75rem; /* 28px */
  --text-xl-size: 1.875rem; /* 30px */
  --text-xl-height: 2.25rem; /* 36px */

  --width-quarter: 0.0625rem; /* 1px */
  --width-half: 0.125rem; /* 2px */
  --width-1: 0.25rem; /* 4px */
  --width-2: 0.5rem; /* 8px */
  --width-3: 0.75rem; /* 12px */
  --width-4: 1rem; /* 16px */
  --width-5: 1.25rem; /* 20px */
  --width-6: 1.5rem; /* 24px */
  --width-7: 1.75rem; /* 28px */
  --width-8: 2rem; /* 32px */
  --width-9: 2.25rem; /* 36px */
  --width-10: 2.5rem; /* 40px */
  --width-11: 2.75rem; /* 44px */
  --width-12: 3rem; /* 48px */
  --width-14: 3.5rem; /* 56px */
  --width-16: 4rem; /* 64px */
  --width-20: 5rem; /* 80px */
  --width-24: 6rem; /* 96px */
  --width-28: 7rem; /* 112px */
  --width-32: 8rem; /* 128px */
  --width-36: 9rem; /* 144px */
  --width-40: 10rem; /* 160px */
  --width-44: 11rem; /* 176px */
  --width-48: 12rem; /* 192px */
  --width-52: 13rem; /* 208px */
  --width-56: 14rem; /* 224px */
  --width-60: 15rem; /* 240px */
  --width-64: 16rem; /* 256px */
  --width-68: 12rem;
  --width-72: 18rem; /* 288px */
  --width-80: 20rem; /* 320px */
  --width-96: 24rem; /* 384px */
  --width-128: 32rem; /* 512px */
  --width-144: 36rem; /* 576px */
  --width-160: 40rem; /* 640px */
  --width-224: 56rem; /* 896px */
  --width-240: 60rem; /* 960px */
  --width-256: 64rem; /* 1024px */
  --width-320: 80rem; /* 1280px */

  --spacing-1: var(--width-1);
  --spacing-2: var(--width-2);
  --spacing-3: var(--width-3);
  --spacing-4: var(--width-4);
  --spacing-6: var(--width-6);
  --spacing-8: var(--width-8);
  --spacing-10: var(--width-10);

  --gap-xs: var(--spacing-1);
  --gap-sm: var(--spacing-2);
  --gap: var(--spacing-3);

  --clr-neutral-50: #f8f7f7;
  --clr-neutral-100: #f3f2f2;
  --clr-neutral-200: #e7e4e4;
  --clr-neutral-300: #ceccca;
  --clr-neutral-500: #737f8c;
  --clr-neutral-700: #5c6670;
  --clr-neutral-900: #2e3338;
  --clr-green-100: #e6ebe0;
  --clr-green-200: #c8d4b7;
  --clr-green-700: #859a5d;
  --clr-green-900: #4d5734;
  --clr-sky-100: #ecf3f9;
  --clr-sky-200: #c5daec;
  --clr-sky-700: #346da0;
  --clr-sky-900: #1d3d59;
  --clr-red-100: #fceae9;
  --clr-red-200: #f4c1bd;
  --clr-red-700: #9f332b;
  --clr-red-900: #5e1d18;
  --clr-yellow-100: #fbf0d0;
  --clr-yellow-200: #F5DA8A;
  --clr-yellow-700: #c49811;
  --clr-yellow-900: #84650b;

  --rounded-sm: 0.125rem; /* 2px */
  --rounded: 0.25rem; /* 4px */
  --rounded-md: 0.375rem; /* 6px */
  --rounded-full: 9999px;

  --sidebar-width: var(--width-64);
  --sidebar-width-collapsed: var(--width-16);

  --header-group-direction: row;
  --header-group-align: center;
  --modal-padding: var(--spacing-6);
  --modal-top: 50%;
  --modal-bottom: unset;
  --modal-left: 50%;
  --modal-right: unset;
  --modal-transform: translate(-50%, -50%);
  --slide-show-aside-left: unset;
  --slideshow-aside-width: var(--width-128);
  --filelist-width: calc(var(--spacing-4) * 3 + 800px);
  --horizontal-padding: var(--spacing-4);
  --card-width: var(--width-52);
  --filelist-width: calc(var(--spacing-4) * 3 + 4 * var(--card-width));
  --app-width-max: calc(var(--filelist-width) + var(--width-20));
  --app-width-max-fullscreen: calc(
    var(--app-width-max) + var(--spacing-4) + var(--card-width)
  );
}

/*
@media (max-width: 767px): Typically used for smartphones and small mobile devices.
@media (min-width: 768px): Often considered as the starting point for tablets and larger 
@media (min-width: 768px): Suitable for most tablets and smaller desktop screens
@media (min-width: 992px): Commonly used for larger tablets and medium-sized desktop screens.
@media (min-width: 1200px): Often used for larger desktop screens.
@media (min-resolution: 2dppx): Targets devices with a pixel density of 2 or more, such as Retina displays.
*/

body {
  margin: 0;
  padding: 0;
  font-family: "Source Sans Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--clr-neutral-900);
  font-size: var(--text-base-size);
  line-height: var(--text-base-height);
}
@media (max-width: 574px) {
  :root {
    --card-width: 100%;
  }
}
@media (max-width: 993px) {
  :root {
    --slideshow-aside-width: var(--width-96);
  }
}
@media (max-width: 767px) {
  :root {
    --modal-top: 0;
    --modal-bottom: 0;
    --modal-left: 0;
    --modal-right: 0;
    --modal-transform: unset;
    --header-group-direction: column;
    --header-group-align: flex-start;
    --slide-show-aside-left: 0;
    --slideshow-aside-width: unset;
    --app-width-max-fullscreen: var(--app-width-max);
  }
}
@media (max-width: 1240px) {
  :root {
    --filelist-width: calc(var(--spacing-4) * 2 + 3 * var(--width-52));
    --app-width-max: calc(var(--filelist-width) + var(--width-20));
  }
}
@media (max-width: 800px) {
  :root {
    --filelist-width: calc(var(--spacing-4) + 2 * var(--width-52));
    --app-width-max: calc(var(--filelist-width) + var(--width-20));
  }
}
@media (max-width: 574px) {
  :root {
    --filelist-width: 100%;
    --app-width-max: 100%;
  }
}
@media (min-width: 1500px) {
  :root {
    --filelist-width: calc(var(--spacing-4) * 4 + 5 * var(--width-52));
    --app-width-max: calc(var(--filelist-width) + var(--width-20));
  }
}
@media (min-width: 1800px) {
  :root {
    --filelist-width: calc(var(--spacing-4) * 5 + 6 * var(--width-52));
    --app-width-max: calc(var(--filelist-width) + var(--width-20));
  }
}
@media (min-width: 2000px) {
  :root {
    --filelist-width: calc(var(--spacing-4) * 6 + 7 * var(--width-52));
    --app-width-max: calc(var(--filelist-width) + var(--width-20));
  }
}

* {
  font-family: "Source Sans Pro", sans-serif;
}

body.overlay-open,
body.slideshow-open {
  overflow: hidden;
}

button {
  font-size: var(--text-base-size);
  line-height: var(--text-base-height);
  padding: var(--spacing-2) var(--spacing-4);
  border: 0;
  border-radius: var(--rounded-md);
  color: var(--clr-sky-700);
  cursor: pointer;
}

button:hover {
  color: var(--clr-sky-900);
}

p {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  font-size: var(--text-base-size);
  line-height: var(--text-base-height);
  margin: 0;
  padding: 0;
}

h1 {
  font-size: var(--text-xl-size);
  line-height: var(--text-xl-height);
}

h2 {
  font-size: var(--text-lg-size);
  line-height: var(--text-lg-height);
}

a {
  color: var(--clr-sky-700);
  text-decoration: none;
}

a:hover {
  color: var(--clr-sky-900);
  text-decoration: underline;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.hide {
  display: none;
}

.valid {
  color: limegreen;
  margin-left: 0.25rem;
}

.invalid {
  color: red;
  margin-left: 0.25rem;
}
